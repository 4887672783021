<template>
	<div>
		<div class="d-flex">
			<span class="text-h5 font-weight-bold mb-3">
				{{ offer.title }}
			</span>
		</div>
		<div class="d-flex justify-left mb-3">
			<span class="body-2 mr-3">
				<v-icon class="mr-1">mdi-domain</v-icon>
				<template v-if="offer.companyName"> {{ $t('offers.managedBy', { name: offer.companyName }) }} </template>
				<router-link class="text-subtitle-2 text-decoration-none red--text" :to="{ name: 'Company', params: { id: offer.company.id } }">
					{{ offer.company.name }}
				</router-link>
			</span>
		</div>
		<div class="d-flex justify-left mb-3">
			<span class="body-2">{{ offer.info.type.name }} - {{ offer.info.time.name }}</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OfferInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	}
}
</script>
